import { observer } from 'mobx-react';
import { TopRibbon, Logo, Title, Drawer, IconButton } from '@strategies/ui';
import LogoImage from '../Map/assets/PULSE.png'
import { FiInfo, FiPrinter } from 'react-icons/fi'
import { useUiModel } from '../hooks/useUiModel';
export interface ITitleProps {

}

export default observer(function TitleComp() {

  const ui = useUiModel();
  return <div className={'TitleComp'}>


    <TopRibbon className="MenuBar">
      <Logo><img src={LogoImage} alt="Pulse Logo" /></Logo>
      <Title>DENVER DOWNTOWN</Title>
      <Drawer>
        <IconButton onClick={() => ui.setInfoModal(true)}>
          <FiInfo />
        </IconButton>
        {/* <IconButton onClick={() => ui.setPrintModal(true)}>
          <FiPrinter />
        </IconButton> */}
      </Drawer>
    </TopRibbon>
  </div>
});
