import { observer } from 'mobx-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { NIGHT_BREAK } from '../../App';
import { useMemo } from 'react';
import { useFeatureModel } from '../Map/hooks/useFeatureModel';

export interface IHoursPanelProps {
  children?: React.ReactNode;
}

export default observer(function HoursPanel({ children }: IHoursPanelProps) {
  const feature = useFeatureModel();



  const filtered_count = useMemo(() => { //@ts-ignore
    return feature.openDayPoints.length;
  }, [feature.openDayPoints])


  const alls_count = useMemo(() => {
    return feature.filteredPoints.length;
  }, [feature.filteredPoints])

  return (<div className={'HoursPanel'}>

    {(feature.filterOpenHoursPanel) ? (<>

      <div className='infoTextWrapper'>
        <><b>  {alls_count}</b> establishments are <b> Open</b></>

      </div>
      <div className='hourWrapper'>

        <div className={'HourTextWrapper'}>
          <span className='hour_text'>

            Hour
          </span>
        </div>
        <div className='hoursWrapper'>

          <span className={`hour_holder`}>{
            ((feature.nighttimeBreakpoint % 12) === 0) ? 12 : feature.nighttimeBreakpoint % 12}{(feature.nighttimeBreakpoint < NIGHT_BREAK) ? "AM" : "PM"}</span>
          <Slider min={6} max={17} value={(feature.nighttimeBreakpoint < NIGHT_BREAK) ? +feature.nighttimeBreakpoint + 12 : feature.nighttimeBreakpoint} onChange={(val: number | number[]) => {
            feature.setNighttimeBreakpoint(+val % 12);

          }} />
        </div>

      </div>
      {children}

    </>) : <><div className='infoTextWrapperOff'>
      <><b>  {filtered_count}</b> establishments are <b> Open</b></>

    </div></>}


  </div>)
});
