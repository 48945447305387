import { observer } from 'mobx-react';
import { Modal, Title, Body, Buttons, Button } from '@strategies/ui';
import { useUiModel } from '../hooks/useUiModel';



export default observer(function InfoModal() {
    const ui = useUiModel();


    return <Modal
        className='InfoModal'
        active={ui.infoModalIsOpen}
        onClose={() => ui.setInfoModal(false)}
    >
        <Title>
            Information
        </Title>
        <Body>
            <ul>
                <li>
                    PRODUCT
                </li>
                <li><span>Pulse by SASAKI</span></li>
                <li>
                    <span>
                        <p>
                            The data gathered here is aggregated from Google Maps and compiled into an interactive product to give context analysis for design projects and to inform design and strategy decisions.
                        </p> 
                    </span>
                </li>
                <hr></hr>
                <li>
                    CONTACT
                </li>
                <li>
                    <span>strategies@sasaki.com</span>
                </li>
                {/* <hr></hr> */}
                {/* <li>
                    USER GUIDE
                </li>
                <li>
                    <span>LINK</span>
                    </li> */}
            </ul>


        </Body>
    </Modal>
});
