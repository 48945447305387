import { Checkbox, List, ListMode } from "@strategies/ui";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { category_color_map } from "../Map/Colors";
import { useFeatureModel } from "../Map/hooks/useFeatureModel";
import ItemCategory from "./ItemCategory";

export interface ICategoryBreakdownPanelProps {
    children?: React.ReactNode;
}

export default observer(function CategoryBreakdownPanel({
    children,
}: ICategoryBreakdownPanelProps) {
    const features = useFeatureModel();

    const sortedCats = useMemo(() => {
        //@ts-ignore
        const sorted = Object.entries(features.subCategoryPointscountsPerPoints).sort((a, b) => a[1] > b[1] ? -1 : 1).map((a) => a[0])
        const sortedCount = sorted.splice(0,7).reduce((cul, cat)=>{ //@ts-ignore
            cul[cat] = features.subCategoryPointscountsPerPoints[cat];
            return cul;
        }, {})
        // @ts-ignore
        return sortedCount
        // return Object.entries(features.subCategoryPointscountsPerPoints).sort((a, b) => a[1] > b[1] ? -1 : 1).map((a) => a[0]);
    }, [features.filterSubCategoryCategory, features.walk, features.sourceIsochrone, features.filterCategory, features.filterWalkMinsPanel]);


    return <div className={"CategoryBreakdownPanel"} onMouseLeave={e=>features.setHoverSubCategoryFilter(undefined)}>
         {/* {features.filterSubCategoryCategory} */}
         {Object.entries(sortedCats).map((cat, i) => ( // @ts-ignore
         <div className={`subCategoryBreakdownitem ${(features.hoverSubCategoryFilter && features.hoverSubCategoryFilter === cat[0]) ? "-select": ""}`} onClick={e=>{features.setHoverSubCategoryFilter(cat[0])}}><div><div className="item-legend" style={{"background-color": 'white'}}></div> {cat[0]}<div></div><div className="subCount">{cat[1]}</div></div></div>                        
         ))}

        {(Object.entries(sortedCats).length === 7) ? <div className="subCategoryBreakdownitem"><div>...</div></div>:<></>}
         {children}</div>
});
