import { useContext, createContext } from "react";
import FeaturesModel from "../../../store/FeatureModel";


const context = createContext(new FeaturesModel());

export function useFeatureModel() {

    return useContext(context);

}
