import { useContext, createContext } from "react";
import { UiModel } from "../../store/UiModel"


const context = createContext(new UiModel());

export function useUiModel() {

    return useContext(context);

}
