import { observer } from 'mobx-react';
import { useState } from 'react';

import { Popup } from 'react-map-gl';

export type IPopUpComp = {
    feature: any
    onClosePopup?: ()=>void;
}

export default observer(function PopUpComp({feature, onClosePopup}:IPopUpComp) {

    const [active, setActive] = useState(true)
    
    return <>{active && (<Popup
        className='Popup'
        latitude={feature.feature.properties!.latitude}
        longitude={feature.feature.properties!.longitude}
        onClose={onClosePopup}
    >
        <div>

            <p>Name: {feature.feature.properties!.name}</p>
            <p>Category: {feature.feature.properties!.type} | {feature.feature.properties!.upper_category}</p>
        </div>
        

    </Popup>)}m
    </>

})
