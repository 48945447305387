import { observer } from 'mobx-react';
import { useEffect } from 'react'; 
import { MapboxExportControl, Size, PageOrientation, Format, DPI } from "@watergis/mapbox-gl-export";
import '@watergis/mapbox-gl-export/css/styles.css';
import {
    useMap
} from "react-map-gl";
import { useFeatureModel } from '../hooks/useFeatureModel';
export interface IExportCompProps {
    children?: React.ReactNode;
}

export default observer(function ExportComp({ children }: IExportCompProps) {
    const { pulseMap } = useMap();
    useEffect(() => {
        console.log("p", pulseMap)
        if (!pulseMap) return;
        pulseMap.addControl(new MapboxExportControl({
            PageSize: Size.A3,
            PageOrientation: PageOrientation.Portrait,
            Format: Format.PNG,
            DPI: DPI[96],
            Crosshair: true,
            PrintableArea: true,
            accessToken: "pk.eyJ1IjoidGFkaXJhbWFuIiwiYSI6IktzUnNGa28ifQ.PY_hnRMhS94SZmIR2AIgug"
        }), 'top-left');

    }, [pulseMap])
    return <div className={'ExportComp'}>{children}</div>

});
