import {observer} from 'mobx-react';

import {FillLayer, LineLayer, Source, Layer} from 'react-map-gl';
import {useFeatureModel} from './hooks/useFeatureModel';
import {featureCollection} from '@turf/helpers';


export default observer(function BoundaryFeatures() {
    const features = useFeatureModel();
    const fillLayer: FillLayer = {
        id: 'isochrone-fill',
        source: 'sf-neighborhoods',
        type: 'fill',
        paint: {
            'fill-outline-color': '#9467bd',
            'fill-color': '#9467bd',
            'fill-opacity': 0.0
        }
    };

    const lineLayer: LineLayer = {
        id: 'isochrone-outline',
        source: 'isochrones',
        type: 'line',
        paint: {
            'line-width': 3,
            'line-color': '#9467bd',
            'line-opacity': 0.75,
            'line-dasharray': [2, 1]
        },
        layout: {
            'line-join': 'round'
        }
    };


    return <div className={'BoundaryFeatures'}>

        {features.filterWalkMinsPanel && <Source id='isochrones-source' type='geojson'  // @ts-ignore
                data={{...featureCollection(features.filteredIsochrone)}}>
            <Layer {...lineLayer} ></Layer>
            <Layer {...fillLayer} ></Layer>
        </Source>}
    </div>
});
