import { observer } from 'mobx-react';
import { LineLayer, Source, Layer, SymbolLayer } from 'react-map-gl';
import { useFeatureModel } from './hooks/useFeatureModel';
import { featureCollection } from '@turf/helpers';
import { useEffect } from 'react';

import { useMap } from 'react-map-gl';

export default observer(function NeighborhoodFeatures() {
    const features = useFeatureModel();
    const { pulseMap } = useMap();
    
    // useEffect(()=>{
    //     console.log("map")
    //     console.log(pulseMap)
    //     if (!pulseMap) return;
        
    //     pulseMap?.getMap().on('style.load', () => {
            
    //         // Insert the layer beneath any symbol layer.
    //         const layers = pulseMap?.getMap().getStyle().layers;
            
    //         // @ts-ignore
    //         const labelLayerId = layers.find(// @ts-ignore
    //             (layer) => layer.type === 'symbol' && layer.layout['text-field']
    //             ).id;
             
    //         // The 'building' layer in the Mapbox Streets
    //         // vector tileset contains building height data
    //         // from OpenStreetMap.
    //         pulseMap?.getMap().addLayer(
    //         {
    //             'id': 'add-3d-buildings',
    //             'source': 'composite',
    //             'source-layer': 'building',
    //             'filter': ['==', 'extrude', 'true'],
    //             'type': 'fill-extrusion',
    //             'minzoom': 15,
    //             'paint': {
    //             'fill-extrusion-color': '#aaa',
                
    //             // Use an 'interpolate' expression to
    //             // add a smooth transition effect to
    //             // the buildings as the user zooms in.
    //             'fill-extrusion-height': [
    //                 'interpolate',
    //                 ['linear'],
    //                 ['zoom'],
    //                 15,
    //                 0,
    //                 15.05,
    //                 ['get', 'height']
    //             ],
    //             'fill-extrusion-base': [
    //                 'interpolate',
    //                 ['linear'],
    //                 ['zoom'],
    //                 15,
    //                 0,
    //                 15.05,
    //                 ['get', 'min_height']
    //             ],
    //             'fill-extrusion-opacity': 0.6
    //             }
    //         },
    //         labelLayerId
    //         );
    //         });
    // },[pulseMap])
    
    const symbol_layer: SymbolLayer = {
        id: 'neighborhoods-label',
        type: 'symbol',
        source: 'neighborhoods',
        layout: {
            "text-field": '{ntaname}',
        },
        paint: {
            'text-color': "#9467bd"
        }
    };

    const lineLayer: LineLayer = {
        id: 'neighborhood-outline',
        source: 'neighborhoods',
        type: 'line',
        paint: {
            'line-width': 1,
            'line-color': '#9467bd',
            'line-opacity': 0.55,
            'line-dasharray': [2, 1]
        },
        layout: {
            'line-join': 'round'
        }
    };


    return <div className={'NeighborhoodFeatures'}>

        <Source id='neighborhoods' type='geojson'  // @ts-ignore
            data={{ ...featureCollection(features.neighborhoodCenters) }}>
            <Layer {...symbol_layer} ></Layer>
        </Source>
        <Source id='neighborhood-outline' type='geojson'  // @ts-ignore
            data={{ ...featureCollection(features.neighborhoods.features) }}>
            <Layer {...lineLayer} ></Layer>
        </Source>
    </div>
});
