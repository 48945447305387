export const category_color_map: any = ({
    Education: "#1f77b4",
    "Outdoors / Active": "#2ca02c",
    "Medical": "lightblue",
    Tourist: "#e377c2",
    "Open Space": "#7CB62F",
    "Food/Beverage": "#F0724E",
    "Business": "#8c564b",
    Parking: "#7f7f7f",
    Nightlife: "#9467bd",
    "Entertainment/Recreation": "#9467bd",
    "Religious": "lightgreen",
    "Residential": "#4DB1EF",
    "Corner / Drug Store": "#a80000",
    "Grocery/ Convenience": "#a80000",
    "F&B": "#ff94f6",
    "Cultural Event Spaces": "#DDAC00",
    "Lodging": "#bcbd22",
    "Organizations": "#abaaf3",
    "Bike rental": "#2ca02c",
    Housing: "#e377c2",
    "Active Business Use": "#fe714d",
    "Government/Civic": "#949BE0",
    "Services": "#A5B5BB",
    Other: "white",
    Retail: "#CF72E3",
    "Shopping/Retail": "#d62728",
    "Parks/Open Spaces": "#2ca02c",
    "Select All": "white",
    "Sports": "#9467bd",
    "Transportation": "lightgray",
    "Playground": "black",
    "Dog park": "white",
    "Health/Wellness": "darkblue",
    "Banking": "#ff94f6"
})

export const circle_color_spec_export = ():any[] => {
    let circle_color_spec = [
    ];
    for (let k in category_color_map) {
        circle_color_spec.push(k, category_color_map[k]);
    }
    circle_color_spec.push("#2ca02c"); // other
    return circle_color_spec;
}