import { computed } from 'mobx';
import {default as datapoints} from '../components/Map/assets/DENVERPULSE9.json'; //points3.json'
import boundry  from '../components/Map/assets/boundry.json'
import {default as isochrones}  from '../components/Map/assets/all_isochrones_updates.json'
import {default as neighborhoods}  from '../components/Map/assets/nhoods.json'
import {default as neighborhood_centers}  from '../components/Map/assets/nhood_center.json'


export default class Data {

    @computed
    static get geoPoint() {
        
        return datapoints;
    }

    @computed
    static get boundary() {

        return boundry;
    }

    @computed
    static get isochrones() {

        return isochrones;
    }

    @computed
    static get neighborhoods() {

        return neighborhoods;
    }

    @computed
    static get neighborhood_centers() {

        return neighborhood_centers;
    }

}


