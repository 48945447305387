import { observer } from "mobx-react";

import { Source, Layer } from "react-map-gl";
import { useFeatureModel } from "./hooks/useFeatureModel";
import { feature, featureCollection } from "@turf/helpers";
import { circle_color_spec_export } from "./Colors";

import type { CircleLayer } from "react-map-gl";
import { useMemo } from "react";

export default observer(function CircleFeatures() {
    const circle_color_ = circle_color_spec_export();
    const features = useFeatureModel(); 

    const filter = useMemo(() => ['in', 'upper_category', `${features.filterSubCategoryCategory}`], [features.filterSubCategoryCategory, features.walk]);
    const unFilter = useMemo(() => ['!=', 'upper_category', `${features.filterSubCategoryCategory}`], [features.filterSubCategoryCategory, features.walk]);
    // console.log("filter", filter)

    const filterLayerStyle: CircleLayer = useMemo(()=>{
        return {
            id: `vis-places-highlight`,
            type: "circle",
            paint: {
                "circle-color": [
                    "match",
                    ["get", "category"],
                    ...circle_color_,
                ],
                "circle-opacity": 0.6,
                "circle-radius": 8,
            },
        }
    }, [])

    const layerStyle: CircleLayer = useMemo(() => {
        return {
            id: `vis-places`,
            type: "circle",
            paint: {
                "circle-color": [
                    "match",
                    ["get", "upper_category"],
                    ...circle_color_,
                ],
                "circle-opacity": 0.6,
                "circle-radius": 8,
            },
        };
    }, [features.walk, features.filterOpenHoursPanel]);

    return (
        <div className={"CircleFeatures"}>
            <Source
                id="circle-features-source"
                type="geojson"
                //@ts-ignore
                data={{ ...featureCollection(features.filteredPoints) }}
            >   
            
            <Layer {...layerStyle}></Layer>
                {/* {(features.filterSubCategoryCategory) ? <Layer {...filterLayerStyle} filter={filter}></Layer>: <Layer {...layerStyle} ></Layer>}  */}
                
            </Source>
        </div>
    );
});
