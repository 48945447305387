import { observer } from 'mobx-react';

export interface IWalkshedOriginPanelProps {
  value?: string;
  setValue?: (value: string) => void;
  children?: React.ReactNode;
}

export default observer(function WalkshedOriginPanel({value, setValue, children}: IWalkshedOriginPanelProps) {
  return <div className={'WalkshedOriginPanel'}>{children}</div>
});
