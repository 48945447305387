import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ListItem, useList, Checkbox } from "@strategies/ui";
import { useFeatureModel } from "../Map/hooks/useFeatureModel";

export interface IItemCategoryProps {
    categoryLabel: string;
    category_color_map: any;
    onSetCategory: (c: string) => void;
    children?: React.ReactNode;
    style?:any;
}

export default observer(function ItemCategory({
    categoryLabel,
    category_color_map,
    onSetCategory,
    children,
    style
}: IItemCategoryProps) {
    const [active, setActive] = useState<boolean>(false);
    const features = useFeatureModel();

    useEffect(() => {
        if (categoryLabel === "Select All" ) {
            setActive(true);
        }
    }, [features.filterCategory]);

    return (
        <div className={"ItemCategory"} style={style}>
            {/* <ListItem
        className='checklistItem'
        value={Array.from(features.filterCategory).includes(categoryLabel) } //@ts-ignore
        onClick={() => {
          onSetCategory(categoryLabel)
          setActive(!active);
        }}
        
      > */}

          {   <div className="rectWrapper">
                 {categoryLabel !== "Select All" && <Checkbox
                    value={
                        ((categoryLabel.length > 0 &&
                        Array.from(features.filterCategory).includes(
                            categoryLabel
                        )) || (categoryLabel === "Select All" && features.filterCategory.length !== 0)) ? true : false
                    }
                    onClick={() => {
                        onSetCategory(categoryLabel);
                        // setActive(
                        //     features.filterCategory.length > 0 ? !active : false
                        // );
                    }}
                />}
                {children}
                <div
                    className="rects"
                    style={{
                        backgroundColor: `${category_color_map[categoryLabel]}`,
                    }}
                    onClick={() => {
                        onSetCategory(categoryLabel);
                        setActive(!active);
                    }}
                ></div>
                <div
                    className={"categoryLabel"}
                    onClick={() => {
                        onSetCategory(categoryLabel);
                        setActive(!active);
                    }}
                >
                    {categoryLabel}
                </div>

                { (categoryLabel === "Select All" ) ? <></> : <div className={active ? "countPerCats" : "countPerCatsOff"}>
                    {categoryLabel === "Select All"
                        ? features.countAll
                        : features.countPerCategories[categoryLabel]}
                </div>}

                <div className={`bardivs${(categoryLabel==="Select All") ? "-all" : ""}`}
                style={{
                    // backgroundColor: color,
                    width: (categoryLabel==="Select All") ? ('0%') : (100 * features.countPerCategories[categoryLabel] / features.countAll) + '%',
                    height: 25,
                }}

                >
                    {/* {(features.countPerCategories[categoryLabel] / features.countAll)} */}
                    
                    </div>

                    {(features.filterSubCategoryCategory &&
                        features.filterSubCategoryCategory === categoryLabel) ? <>
                            
                        {/* subcategories */}
                        
                        
                        
                        
                        </> : <></>}

                {/* <svg width={100} height={24} className="bars">
                <g transform={`translate(${10},1)`} >



                    <rect
                        x={0}
                        height={20}
                        strokeOpacity={0.4}
                        // stroke={"#050038"}
                        fill={"#F2F5FA"}
                        width={"50"}
                    >
                    </rect>
                </g>

            </svg> */}
            </div>
        }
            
            {/* </ListItem> */}
        </div>
    );
});
