import { action, computed, observable, makeObservable } from 'mobx';
export class UiModel {
    constructor() {
        makeObservable(this);
    }

    @observable
    infoModalIsOpen: boolean  = false;

    @action
    setInfoModal(modalOpen: boolean){
        this.infoModalIsOpen = modalOpen
    }

    @observable
    printModalIsOpen: boolean  = false;

    @action
    setPrintModal(modalOpen: boolean){
        this.printModalIsOpen = modalOpen
    }

}