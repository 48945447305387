import { Button, Buttons, Checkbox, List, ListMode } from "@strategies/ui";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { category_color_map } from "../Map/Colors";
import { useFeatureModel } from "../Map/hooks/useFeatureModel";
import ItemCategory from "./ItemCategory";

export interface ICategoriesPanelProps {
    children?: React.ReactNode;
}

export default observer(function CategoriesPanel({
    children,
}: ICategoriesPanelProps) {
    const features = useFeatureModel();
    const [activeAll, setActiveAll] = useState<boolean>(true);
    

    const sortedCats = useMemo(() => {
        // @ts-ignore
        return Object.entries(features.countPerCategories).sort((a, b) => a[1] > b[1] ? -1 : 1).map((a)=> a[0]);
    }, [features.countPerCategories]);

    

    return features ? (
        <>
            {" "}
            <div className={"CategoriesPanel"}>
                <List mode={ListMode.RADIO}>
                    
                    <div className="categoryInPanel" style={{height:(features.itemCategoryHeight + features.itemCategoryHeightPadding) * (Object.keys(features.allCountPerCategories).length)}}>
                        <div className="select-deselct-all">

                            <Button 
                            onClick={()=>{
                                console.log("on sert??")
                            features.unFilterCategory();
                            setActiveAll(!activeAll);
                            features.setSelectAll(!features.selectAll)
                            }}
                        >
                            {(features.selectAll) ? "Select All" : "Deselect"}
                        </Button>
                        </div>
                        
                    {/* <ItemCategory   
                        onSetCategory={() => {
                            console.log("on sert??")
                            features.unFilterCategory();
                            setActiveAll(!activeAll);
                            features.setSelectAll(!features.selectAll)
                        }}
                        category_color_map={category_color_map}
                        categoryLabel={(features.selectAll) ? "Select All" : "Deselect"}
                    /> */}
                        {Object.entries(features.countPerCategories).map((catCounts, i) => ( // @ts-ignore
                        // (catCounts[1] > 0) &&
                         <ItemCategory
                            // make sure to add the key
                            key={`${catCounts[0]}`}
                            categoryLabel={catCounts[0]}
                            category_color_map={category_color_map}
                            onSetCategory={(c: string) => {
                                // console.log("setting category to", c)
                                features.setFilterCategory(c);
                                
                                if (features.filterSubCategoryCategory === c) {
                                    features.setFilterSubCategoryCategory(undefined);
                                } else {
                                    features.setFilterSubCategoryCategory(c);
                                }
                            }}
                            style={{"top": `${((sortedCats.indexOf(catCounts[0]) ) * features.itemCategoryHeight) + (features.itemCategoryHeight + features.itemCategoryHeightPadding)}px`}}
                        />
                    ))}
                    </div>
                    
                    {children}
                </List>
            </div>
        </>
    ) : (
        <></>
    );
});
