import { observer } from 'mobx-react';
import { Panel, Title, Body, Toggle } from '@strategies/ui';

export interface IMapLegendProps {
    title: string;
    children?: React.ReactNode;
    headerChildren?: React.ReactNode;
    toggleIcon?: React.ReactNode;
}

export default observer(function MapLegend({ title, children, headerChildren,toggleIcon }: IMapLegendProps) {
    return (

        <div className={'MapLegend'}>
            <Panel active={true}>
                <Title>
                   
                    <div className='hicon'>
                            <Toggle>
                            {toggleIcon}
                            </Toggle>
                          
                        </div>
                   {title}

                </Title>
                {headerChildren}
                <Body>
                    {children}
                </Body>
            </Panel>
        </div>
    )
});
